import React from "react";
import Box from "../components/Box";
import { colors } from "douglasfirearms-shared";
import {
  BoxFormHeader,
  BoxText,
  BoxHeaderIcon,
} from "../components/Typography";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function () {
  return (
    <Box>
      <BoxHeaderIcon
        color={colors.success}
        size="64px"
        sizeXs="56px"
        icon={faCheckCircle}
      />
      <BoxFormHeader>You're good to go!</BoxFormHeader>
      <BoxText>Looking forward to seeing you at the course!</BoxText>
      <BoxText>
        Expect to receive 2-3 text messages with more information
        leading up to the class date.
      </BoxText>
    </Box>
  );
}
