import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Field } from "douglasfirearms-shared";
import { CheckoutStates } from "../../../constants/states";
import { executeSignup } from "../../../api";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

export default function ({ signupData, paymentAmount } = {}) {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutState, setCheckoutState] = useState(CheckoutStates.FORM_READY);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      setCheckoutState(CheckoutStates.FORM_SUBMITTING);
      executeSignup({
        paymentAmount,
        paymentMethod,
        ...signupData,
      })
        .then(() => {
          // TODO redirect to success page
          setCheckoutState(CheckoutStates.SUCCESS);
        })
        .catch((err) => {
          console.error(err)
          console.error(err.response)
          console.error(err.data)
          if (err?.response?.data?.errorCode === 'STRIPE_ERROR' && err?.response?.data?.message) {
            toast.error(
              err.response.data.message
            );
          } else {
            toast.error(
            "An error occurred executing your signup. Please try again."
          );
          }
          
        })
        .finally(() => {
          setCheckoutState(CheckoutStates.FORM_READY);
        });
    }
  };

  const CARD_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
      },
    },
  };

  if (checkoutState === CheckoutStates.SUCCESS) {
    return <Redirect to="/confirmation" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Field as={CardElement} options={CARD_OPTIONS} type="StripeCardElement" />
      <Button
        type="submit"
        disabled={!stripe}
        width="100%"
        height="56px"
        style={{ marginTop: "32px" }}
        showSpinner={checkoutState === CheckoutStates.FORM_SUBMITTING}
      >
        Pay ${paymentAmount}
      </Button>
    </form>
  );
}
