import React from "react";
import { Field } from "douglasfirearms-shared";

export default function ({ signupData, paymentAmount, onChange } = {}) {
  return (
    <div>
      <Field
        as="select"
        label="Deposit amount"
        onChange={onChange}
        alwaysFloatLabel={true}
      >
        <option
          value={signupData.initialDeposit}
          selected={paymentAmount === signupData.initialDeposit}
        >
          Pay ${signupData.initialDeposit} deposit today
        </option>
        <option
          value={signupData.price}
          selected={paymentAmount === signupData.price}
        >
          Pay full ${signupData.price} today
        </option>
      </Field>
    </div>
  );
}
