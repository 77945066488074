import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../Nav";
import { GlobalStyle, Main } from "./style";
import { Reset } from "styled-reset";

export default function ({ children } = {}) {
  return (
    <React.Fragment>
      <Reset />
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <title>Signup | Douglas Firearms Training</title>
        <meta name="description" content="Signup for a Concealed Carry class" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Raleway:wght@600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Nav />
      <Main>{children}</Main>
    </React.Fragment>
  );
}
