import React from "react";
import { NavWrapper, Nav, NavItem } from "./style";
import { Logo } from "douglasfirearms-shared";
import { useLocation } from "react-router-dom";

export default function () {
  const location = useLocation();

  const isPage = (str) => location.pathname.indexOf(str) !== -1;

  return (
    <NavWrapper>
      <Logo type="bare" />
      <Nav>
        <NavItem isActive={isPage("register")}>Register</NavItem>
        <NavItem isActive={isPage("checkout")}>Checkout</NavItem>
        <NavItem isActive={isPage("confirmation")}>Confirmation</NavItem>
      </Nav>
    </NavWrapper>
  );
}
