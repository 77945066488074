import React, { useState } from "react";
import Box from "../components/Box";
import { BoxFormHeader, BoxFormSubheader } from "../components/Typography";
import { RegisterStates } from "../constants/states";
import { Field, Button } from "douglasfirearms-shared";
import { useForm, Controller } from "react-hook-form";
import phone from "phone";
import { Redirect } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { CourseStatus } from "../constants/course-status";

const GET_COURSES = gql`
  {
    courses(status: "${CourseStatus.ACTIVE}") {
      timestamp
      dateDisplay
      price
      initialDeposit
    }
  }
`;

export default function () {
  // Run GraphQL query to get course data
  const { loading: loadingCourses, error, data } = useQuery(GET_COURSES);
  let courses = data?.courses && [...data.courses].sort((a,b) => a.timestamp - b.timestamp);

  // Setup react-hook-form
  const { handleSubmit, errors, control } = useForm({
    defaultValues: JSON.parse(
      window.sessionStorage.getItem("dftSignup") || "{}"
    ),
  });

  // State management
  const [pageState, setPageState] = useState();
  if (pageState === RegisterStates.SUBMIT_SUCCESS) {
    return <Redirect to="/checkout" push={true} />;
  } else if (loadingCourses && pageState !== RegisterStates.LOADING_FORM) {
    setPageState(RegisterStates.LOADING_FORM);
  } else if (error) {
    // TODO handle error by redirecting to error page
  } else if (!loadingCourses && pageState === RegisterStates.LOADING_FORM) {
    setPageState(RegisterStates.PENDING_USER_INPUT);
  }

  // Handle submission
  const onSubmit = async (values) => {
    // Get price data for course
    const { price, initialDeposit } = courses.find(
      (course) => course.timestamp === parseInt(values.courseTimestamp)
    );

    // Format phone number
    let { phoneNumber, ...data } = values;
    phoneNumber = phone(phoneNumber)[0];

    // Set signup data to session storage
    window.sessionStorage.setItem(
      "dftSignup",
      JSON.stringify({
        price,
        initialDeposit,
        phoneNumber,
        ...data,
      })
    );

    // Finalize submission
    setPageState(RegisterStates.SUBMIT_SUCCESS);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <BoxFormHeader>Let's get shooting</BoxFormHeader>
        <BoxFormSubheader maxWidthDesktop="425px">
          Select the course you want to take and give us some basic info to get
          started.
        </BoxFormSubheader>

        <Controller
          name="courseTimestamp"
          control={control}
          rules={{
            required: "Required",
          }}
          render={(props) => (
            <Field
              as="select"
              label="Course"
              error={errors.courseTimestamp}
              {...props}
            >
              <option value="" key="blank">
                {pageState === RegisterStates.LOADING_FORM
                  ? "Loading courses..."
                  : "Select your course"}
              </option>
              {courses?.map((course) => (
                <option key={course.timestamp} value={course.timestamp}>
                  {course.dateDisplay}
                </option>
              ))}
            </Field>
          )}
        />

        <Controller
          name="name"
          control={control}
          render={(props) => (
            <Field
              label="Name"
              placeholder="Enter your Name"
              error={errors.name}
              {...props}
            />
          )}
          rules={{ required: "Required" }}
        />

        <Controller
          name="email"
          control={control}
          render={(props) => (
            <Field
              label="Email address"
              placeholder="Enter your email"
              error={errors.email}
              {...props}
            />
          )}
          rules={{
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
        />

        <Controller
          name="phoneNumber"
          control={control}
          render={(props) => (
            <Field
              label="Phone number"
              placeholder="Enter your phone number"
              error={errors.phoneNumber}
              {...props}
            />
          )}
          rules={{
            required: "Required",
            validate: (value) => phone(value).length || "Invalid phone number",
          }}
        />

        <Button
          type="submit"
          height="56px"
          width="100%"
          showSpinner={pageState === RegisterStates.SUBMITTING}
          retainColor={true}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
}
