import styled from "styled-components";
import { colors } from "douglasfirearms-shared";
import { svgPathData } from "@fortawesome/free-solid-svg-icons/faChevronRight";

export const NavWrapper = styled.nav`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.backgroundDark};
  color: ${colors.primary};
  position: relative;
  > :first-child {
    margin-right: auto;
  }
`;

export const Nav = styled.ul`
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const NavItem = styled.li`
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  ${(props) =>
    props.isActive &&
    `
    font-weight: bold;
    color: rgba(255,255,255, 0.9);
  `}
  + li {
    margin-left: 72px;
    position: relative;
    &::before {
      content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 720"><path fill="rgba(255,255,255, 0.8)" d="${svgPathData}" ></path></svg>');
      position: absolute;
      left: -52px;
      top: 1px;
    }
  }
`;
