import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Box from "../components/Box";
import { BoxFormHeader } from "../components/Typography";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../components/Checkout/PaymentForm";
import DepositSelect from "../components/Checkout/DepositSelect";
import { DisclaimerText } from "../components/Checkout/style";

// Always keep this pormise outside of the render function to avoid recreating stripe object every render
const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function () {
  const signupData = JSON.parse(
    window.sessionStorage.getItem("dftSignup") || "null"
  );

  if (!signupData) {
    return <Redirect to="/register" />;
  }

  const [paymentAmount, setPaymentAmount] = useState(signupData.initialDeposit);

  return (
    <Box>
      <BoxFormHeader>Checkout</BoxFormHeader>
      <DepositSelect
        signupData={signupData}
        paymentAmount={paymentAmount}
        onChange={(e) => setPaymentAmount(e.target.value)}
      />
      <Elements stripe={stripe}>
        <PaymentForm signupData={signupData} paymentAmount={paymentAmount} />
      </Elements>
      <DisclaimerText>
        {paymentAmount === signupData.initialDeposit
          ? `
          Deposit is non-refundable. $${
            signupData.price - signupData.initialDeposit
          } will be due by the morning of the course date.
        `
          : `
          Amount is non-refundable.
        `}
      </DisclaimerText>
    </Box>
  );
}
