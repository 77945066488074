import React from "react";
import styled from "styled-components";
import { colors } from "douglasfirearms-shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BoxFormHeader = styled.h1`
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  + * {
    margin-top: 42px;
  }
`;

export const BoxFormSubheader = styled.p`
  text-align: center;
  max-width: ${(props) => props.maxWidthDesktop || props.maxWidth || "400px"};
  margin: 0 auto 32px;
  margin-top: 20px;
  padding: 0 16px;
  color: #888;
  line-height: 24px;
`;

export const BoxText = styled.p`
  line-height: 24px;
  text-align: center;
  color: #727272;
  margin-top: 24px;
  & + & {
    margin-top: 16px;
  }
`;

const BoxHeaderIconElem = styled(FontAwesomeIcon)`
  text-align: center;
  font-size: ${(props) => props.size || "16px"};
  color: ${(props) => props.color || colors.accentDark};
  @media (max-width: 767px) {
    ${(props) => props.sizeXs && `font-size: ${props.sizeXs};`}
  }
`;

export const BoxHeaderIcon = function (props) {
  return (
    <div style={{ textAlign: "center" }}>
      <BoxHeaderIconElem {...props} />
    </div>
  );
};
