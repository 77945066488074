import React from "react";
import Layout from "./components/Layout";
import { Register, Checkout, Confirmation } from "./pages";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <ToastContainer />
        <Router>
          <Layout>
            <Switch>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/checkout">
                <Checkout />
              </Route>
              <Route path="/confirmation">
                <Confirmation />
              </Route>
              <Route path="/">
                <Redirect to="/register" />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;
