export const RegisterStates = {
  LOADING_FORM: "LOADING_FORM",
  COURSE_LOAD_FAILURE: "COURSE_LOAD_FAILURE",
  PENDING_USER_INPUT: "PENDING_USER_INPUT",
  SUBMISSION_READY: "SUBMISSION_READY",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
};

export const CheckoutStates = {
  FORM_READY: "FORM_READY",
  FORM_SUBMITTING: "FORM_SUBMITTING",
  SUCCESS: "SUCCESS",
};
