import styled from "styled-components";

export const DisclaimerText = styled.p`
  font-size: 14px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 16px;
  text-align: center;
  line-height: 20px;
  color: #727272;
`;
